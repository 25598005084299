<template>
    <v-card >
        <v-card-title class="text-h5">
            ¿Desea eliminar la plantilla seleccionada?
        </v-card-title>

        <v-card-actions style="text-align: center;">
            <v-spacer></v-spacer>
            <v-col cols="12" md="6">
            <v-btn color="red darken-1" text @click="deleteEmail" >
                Si
            </v-btn>
        </v-col>
        <v-col cols="12" md="6">
            <v-btn color="green darken-1" text @click="$emit('close', {isDeleted: false})">
                No
            </v-btn>
        </v-col>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "DeleteHeaderFooter",
        props: ['data'],
        methods: {
            deleteEmail() {
                this.$API.HF.deleteHF(this.data.Id)
                    .then((response) => {
                        this.$toasted.success(response.message);
                        this.$emit('close', {isDeleted: true})
                    })
            }
        }
    }
</script>

<style scoped>

</style>
<template>
    <v-expansion-panel>
      <v-expansion-panel-header>
        Encabezados y pies de página
        <template v-slot:actions>
          <v-btn class="mr-5" @click.native.stop="reloadp++" icon small>
            <v-icon color="primary">mdi-reload</v-icon>
          </v-btn>
          <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content :key="reloadp">
        <v-card>
          <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
            <v-tabs-slider color="red"></v-tabs-slider>
            <v-tab href="#tab-1">
                Encabezados y pies de página
              <v-icon>mdi-email-search</v-icon>
            </v-tab>
            <v-tab href="#tab-2">
                Agregar/Editar Encabezados y pies de página
              <v-icon>mdi-email-plus</v-icon>
            </v-tab>
          </v-tabs>
          
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item value="tab-1">
              <v-card flat>
                <v-card-text class="pa-1">
                  <v-card>
                    <DxDataGrid class=""
                      :data-source="HF"
                      key-expr="Id"
                      :show-row-lines="showRowLines"
                      :show-borders="showBorders"
                      :row-alternation-enabled="rowAlternationEnabled"
                    :scrolling="{ useNative: true }"    
                    :column-auto-width="true">
                     
                      <DxPaging :page-size="10" />
                      
                      <DxColumn
                       :width="80"
                        data-field="Body"
                        caption=""
                        cell-template="body"
                      />
                      <DxColumn data-field="Id" caption="ID"></DxColumn>
                      <DxColumn data-field="Name" caption="Nombre"></DxColumn>
                      <template #body="{ data }">
                        <div>
                         <!-- <a >
                          <v-icon small class="mr-2" color="blue" href="javascript:void(0)" @click="showTemplate(data)"
                          >mdi-eye</v-icon>
                          </a> 
                        -->
                        <a href="javascript:void(0)" @click="templateSelected(data)">
                            <v-icon small class="mr-2" color="primary">mdi-eye</v-icon>
                          </a>
                        </div>
                      </template>
                      <DxColumn data-field="ID_User" caption="ID"></DxColumn>
                      <DxColumn data-field="State" caption="Estado" cell-template="state" />
                      <template #state="{ data }">
                        <v-chip
                          small
                          :color="
                            data.data.State === 'Active' || data.data.State === 1 || data.data.State === '1' ? 'success' : 'grey'
                          "
                          >{{
                            data.data.State === "Active" || data.data.State === 1 || data.data.State === "1" ? "Activo" : "Inactivo"
                          }}</v-chip
                        >
                      </template>
                      <DxColumn
                        data-field="SystemDateInsert"
                        caption="Creado en"
                        cell-template="created-at"
                      />
                      <template #created-at="{ data }">
                        <span>
                          {{
                            moment(data.data.SystemDateInsert).format("DD MMM YYYY, h:mm A")
                          }}
                        </span>
                      </template>
  
                      <DxColumn
                        data-field="SystemDateUpdate"
                        caption="Actualizado en"
                        cell-template="updated-at"
                      />
                      <DxColumn
                        :width="80"
                        cell-template="show-template"
                        caption=""
                      ></DxColumn>
                      <template #updated-at="{ data }">
                        {{
                          moment(data.data.SystemDateUpdate).format("DD MMM YYYY, h:mm A")
                        }}
                      </template>
                      <template #show-template="{ data }">
                        <div>
                          
                          <a
                            href="javascript:void(0)"
                            @click.prevent="
                              emailToDelete = data.data;
                              deleteDialog = true;
                            "
                          >
                            <v-icon small color="error">mdi-delete</v-icon>
                          </a>
                        </div>
                      </template>
                    </DxDataGrid>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-card flat>
                <v-card-text class="pa-1">
                  <v-card class="white pa-8">
                    <ManageHeaderFooter  @reloadData="tab=1;clearfooter();getAllHeaderFooter(); "    @clean="clearfooter" :key="rebuild" :win="win" :HF="HFSelected" :editMood="editMode"></ManageHeaderFooter>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-tab-item>

          </v-tabs-items>
        </v-card>
      </v-expansion-panel-content>
  
      <ShowHFDialogue
        ref="showDialog"
        :data="emailToShow"
        @close="closeShowDialog"
      ></ShowHFDialogue>
  
      <v-dialog v-model="deleteDialog" max-width="500" min-width="500" >
        <DeleteHeaderFooter 
          :data="emailToDelete"
          @close="closeDialog"
        ></DeleteHeaderFooter>
      </v-dialog>
    </v-expansion-panel>
  </template>
  
  <script>
  import { DxDataGrid, DxColumn,  DxPaging } from "devextreme-vue/data-grid";
  import DeleteHeaderFooter from "../header_footer/DeleteHeaderFooter.vue";
  import ShowHFDialogue from "../header_footer/ShowHFDialogue.vue";
  import ManageHeaderFooter from "../header_footer/ManageHeaderFooter.vue";
  import { mapGetters } from "vuex";
  export default {
    name: "header_footer",
    components: {
      DeleteHeaderFooter,
      ShowHFDialogue,
      DxDataGrid,
      DxColumn,
      
      DxPaging,
      ManageHeaderFooter,
    },
    props: ["win"],
    data() {
      return {
        search: "",
        reloadp: 0,
        tab: null,
        HF: [],
        deleteDialog: false,
        emailToDelete: null,
        emailToShow: {
          Email_Body: "",
        },
        editMode:false,
        showRowLines: true,
        showBorders: true,
        rowAlternationEnabled: true,
        HFSelected: null,
        rebuild: 0,
      };
    },
    mounted() {
      this.getAllHeaderFooter();
    },
    computed: {
      ...mapGetters(["reloadData"]),
    },
    methods: {
      getAllHeaderFooter() {
        let search = {};
        search.ID_business = JSON.parse(localStorage.getItem("user")).businessid || ""
        search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || ""
        this.$API.HF.getHF(search).then((response) => {
          this.HF = response;
        });

      },
      showTemplate(data) {
        this.emailToShow = Object.assign({}, data);
        this.$refs.showDialog.showDialog = true;
      },
      closeShowDialog() {
        this.emailToShow = Object.assign({}, { Email_Body: "" });
        this.$refs.showDialog.showDialog = false;
      },
      closeDialog(payload) {
        this.emailToDelete = null;
        this.deleteDialog = false;
        if (payload.isDeleted) {
          this.getAllHeaderFooter();
        }
      },
  
      templateSelected(data) {
        this.HFSelected = data.data;
        this.tab = "tab-2";
        this.editMode = true;
        this.rebuild++;
       /*  this.$store.dispatch("addWindow", {
          name: "AddHeaderFooter",
          component: "AddHeaderFooter",
          unique: true,
          meta: { HF: data.data,editMood:true },
        }); */
      },
      clearfooter(){
        this.HFSelected = null;
        this.editMode = false;
        this.rebuild++;
      }
    },
  };
  </script>
  
  <style scoped></style>
  